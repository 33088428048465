import React, { useContext, useEffect } from 'react';
import logo from '../../images/logo.svg';
import whitelogo from '../../images/whitelogo.svg';
import Player from '@vimeo/player';
import * as styles from './HomeHeroAnimation.module.scss';

import {
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  Sticky,
  ScrollContainerContext,
  ScrollPageContext,
} from 'react-scroll-motion';
import { Link } from 'gatsby';
import RocketAnimation from '../RocketAnimation/RocketAnimation';
import BigCircleCta from '../BigCircleCta/BigCircleCta';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import ContentfulLottieAnimation from '../ContentfulLottieAnimation/ContentfulLottieAnimation';
import useMediaQuery from '../../hooks/useMediaQuery';

const Text = ({ children }) => <p>{children}</p>;
const options = {
  renderNode: {
    paragraph: (node, children) => <Text>{children}</Text>,
  },
};

const Animator = ({ children, animation = {} }) => {
  const { currentPage, currentProgress } = useContext(ScrollContainerContext);

  const { page } = useContext(ScrollPageContext);

  const filterStyle = (style = {}, ...params) => {
    const filteredStyle = { ...style };
    for (const key in filteredStyle)
      if (typeof filteredStyle[key] === 'function')
        filteredStyle[key] = filteredStyle[key](...params);
    return filteredStyle;
  };

  const calculatedStyle =
    currentPage === page // for current (out)
      ? { ...filterStyle(animation?.out?.style, currentProgress) }
      : currentPage === page - 1 // for next (in)
      ? { ...filterStyle(animation?.in?.style, currentProgress) }
      : {};

  return <div style={calculatedStyle}>{children}</div>;
};

const AboutUSSection = ({
  positioningStatementLink,
  positioningStatementLinkText,
  positioningStatement,
}) => (
  <div className={styles.scrollAnimationContentBgr}>
    <ScrollPage page={1}>
      <div className={styles.scrollAnimationContent}>
        <div className={styles.scrollAnimationInfo}>
          <Animator>{renderRichText(positioningStatement, options)}</Animator>
        </div>
        <Link
          to={`/${positioningStatementLink}`}
          className={styles.scrollAnimationCta}>
          <Animator>
            <div className={styles.aboutUsCtaWrapper}>
              <BigCircleCta bgrColor="#a888fd" textColor="#1a1a1a">
                {positioningStatementLinkText}
              </BigCircleCta>
            </div>
          </Animator>
        </Link>
      </div>
    </ScrollPage>
  </div>
);

const BreaktroughAnimation = ({ isDesktop }) => {
  return isDesktop ? (
    <div className={styles.breaktroughTextDesktop}>
      <ContentfulLottieAnimation
        lottieFile={{
          file: { url: '/assets/animations/brave-desktop.json' },
        }}
        offset={0.3}
        scrollProgressLever={1.2}
      />
      <h2>We are brave as a bear</h2>
    </div>
  ) : (
    <div className={styles.breaktroughTextMobile}>
      <ContentfulLottieAnimation
        lottieFile={{
          file: { url: '/assets/animations/brave-mobile.json' },
        }}
        offset={0.2}
      />
      <h2>We are brave as a bear</h2>
    </div>
  );
};

const BgVideo = ({ isDesktop }) => {
  useEffect(() => {
    const herovid = document.querySelector('#herovid');
    const desktopplayer = new Player(herovid);

    const accmode = document.getElementById('acc-mode');

    //store acc mode in session
    if (localStorage.getItem('acc-mode') === 'on') {
      accmode.classList.add('active');
      desktopplayer.pause();
    } else {
      accmode.classList.remove('active');
      desktopplayer.play();
    }

    accmode.addEventListener('click', function (e) {
      //toggle button
      if (accmode.classList.contains('active')) {
        accmode.classList.remove('active');
        desktopplayer.play();
      } else {
        accmode.classList.add('active');
        desktopplayer.pause();
      }
    });
  }, []);

  return isDesktop ? (
    <div id="heroviddesktopcontainer" className={styles.bgVideo}>
      <iframe
        id="herovid"
        src="https://player.vimeo.com/video/1024412152?h=d17adfff9c&dnt=true&amp;title=0&amp;byline=0&amp;portrait=0&amp;api=true&amp;loop=1&amp;autoplay=1&amp;background=1"
        class="lazyloaded"
        allowfullscreen=""
        title="Bernadette showreel"></iframe>
    </div>
  ) : (
    <div id="herovidmobilecontainer" className={styles.bgVideoMobile}>
      <iframe
        id="herovid"
        src="https://player.vimeo.com/video/1024412129?h=515b132eca&dnt=true&amp;title=0&amp;byline=0&amp;portrait=0&amp;api=true&amp;loop=1&amp;autoplay=1&amp;background=1"
        class="lazyloaded"
        allowfullscreen=""
        title="Bernadette showreel"></iframe>
    </div>
  );
};

const HomeHeroAnimation = ({
  positioningStatementLink,
  positioningStatementLinkText,
  positioningStatement,
}) => {
  const isDesktop = useMediaQuery('(min-width: 600px)');

  useEffect(() => {
    const nav = document.querySelector(
      '[class^="Header-module--header-wrapper"]',
    );
    const layoutwrapper = document.querySelector(
      '[class^="Layout-module--layout"]',
    );
    layoutwrapper.classList.add('transparent-nav');

    //transparent to black sticky nav
    nav.classList.remove('filled');
    nav.classList.add('transparent-bg');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        nav.classList.add('filled');
        nav.classList.remove('transparent-bg');
      } else {
        nav.classList.remove('filled');
        nav.classList.add('transparent-bg');
      }
    });
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <RocketAnimation isDesktop={isDesktop} />
      <ScrollContainer>
        <ScrollPage page={0}>
          <BgVideo isDesktop={isDesktop} />
          <Animator animation={batch(Fade(), Sticky())}>
            <div className={styles.scrollAnimation}>
              {/* <p className={styles.scrollAnimationTitle}>We are</p> */}
              <img
                className={styles.scrollAnimationLogo}
                src={whitelogo}
                alt="Bernadette logo"
              />
              <p className={styles.scrollAnimationDescription}>
                A Digital Experience Agency
              </p>
            </div>
          </Animator>
        </ScrollPage>
      </ScrollContainer>
      <AboutUSSection
        positioningStatementLink={positioningStatementLink}
        positioningStatementLinkText={positioningStatementLinkText}
        positioningStatement={positioningStatement}
      />
      <BreaktroughAnimation isDesktop={isDesktop} />
    </div>
  );
};

export default HomeHeroAnimation;
